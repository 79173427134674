/* customPagination.css */

.custom-pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-pagination-item {
  padding: 4px 10px; /* Adjust padding as needed */
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #f8f9fb;
  -webkit-text-fill-color: #5a5c73;
  -webkit-box-shadow: 0 0 0px 1000px #f8f9fb inset;
  transition: background-color 5000s ease-in-out 0s;
}

.disabled-link {
  pointer-events: none;
}

/* Hide scrollbar for variants */

.variantBox::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.variantBox {
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}
.error-div .select__control, .error-div .css-t3ipsp-control:hover{
  border-color: red !important;
  box-shadow: none;
}
.datepicker-input input{
  padding: 9px 14px !important;
  color: #5A5C73;
}
.datepicker-input > div{
  border-color: #E2E8F0 !important;
}
.datepicker-input >div > div:hover{
  border: none !important;
}
.datepicker-input input[type="text"] {
  border: none !important;
}
.datepicker-input>div>div{
  border-radius: 0.5rem;
  border-color: #E2E8F0 !important
}
.datepicker-input>div{
  border-color: #E2E8F0 !important
}
.dashboard-table-div .p-checkbox-box{
  border: 1px solid #E7E9EB;
}
.p-selection-column div {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.p-datatable-tbody .p-highlight.p-selectable-row{
  background: #ddebff;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #0d6efd;
  background: #0d6efd;
}

.glassy{
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.p-paginator-bottom{
  border-radius: 0 0 10px 10px;
}

table td {
  height: 45px;
}
.z100{
  z-index: 100 !important;
}

.gradien {
  background: linear-gradient(133deg,#080f18,#2c248d 99%)
  /* background-position: 37.97vw calc(((300vw - 100vh) / 2 - 85.77vw)* -1), 37.97vw calc(((300vw - 100vh) / 2 - 85.77vw)* -1), 37.97vw calc(((300vw - 100vh) / 2 - 85.77vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 154.56vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 154.56vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 154.56vw)* -1), 13.34vw calc(((300vw - 100vh) / 2 - 53.88vw)* -1), 13.34vw calc(((300vw - 100vh) / 2 - 53.88vw)* -1), 13.34vw calc(((300vw - 100vh) / 2 - 53.88vw)* -1), 10.64vw calc(((300vw - 100vh) / 2 - 17.19vw)* -1), 10.64vw calc(((300vw - 100vh) / 2 - 17.19vw)* -1), 10.64vw calc(((300vw - 100vh) / 2 - 17.19vw)* -1), -49vw calc(((300vw - 100vh) / 2 - 41.1vw)* -1), -49vw calc(((300vw - 100vh) / 2 - 41.1vw)* -1), -49vw calc(((300vw - 100vh) / 2 - 41.1vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 78vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 78vw)* -1), -100vw calc(((300vw - 100vh) / 2 - 78vw)* -1);
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .024) 0%, rgba(160, 51, 255, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .04) 0%, rgba(160, 51, 255, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(160, 51, 255, .064) 0%, rgba(160, 51, 255, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .024) 0%, rgba(24, 119, 242, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .04) 0%, rgba(24, 119, 242, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .064) 0%, rgba(24, 119, 242, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .024) 0%, rgba(255, 108, 92, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .04) 0%, rgba(255, 108, 92, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(255, 108, 92, .064) 0%, rgba(255, 108, 92, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .024) 0%, rgba(245, 206, 51, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .04) 0%, rgba(245, 206, 51, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(245, 206, 51, .064) 0%, rgba(245, 206, 51, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .024) 0%, rgba(37, 211, 102, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .04) 0%, rgba(37, 211, 102, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(37, 211, 102, .064) 0%, rgba(37, 211, 102, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .024) 0%, rgba(24, 119, 242, 0) 50%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .04) 0%, rgba(24, 119, 242, 0) 75%), radial-gradient(50% 50% at 50% 50%, rgba(24, 119, 242, .064) 0%, rgba(24, 119, 242, 0) 100%);
  background-size: 156.45vw 99.03vw, 156.45vw 99.03vw, 156.45vw 99.03vw, 226.86vw 145.44vw, 226.86vw 145.44vw, 226.86vw 145.44vw, 171.96vw 110.31vw, 171.96vw 110.31vw, 171.96vw 110.31vw, 130.29vw 83.58vw, 130.29vw 83.58vw, 130.29vw 83.58vw, 198vw 126.9vw, 198vw 126.9vw, 198vw 126.9vw, 300vw 192vw, 300vw 192vw, 300vw 192vw; */
}
.gradien-light {
  background: linear-gradient(rgb(255 255 255), rgb(247 249 251));
}

body{
  font-family: "Inter", sans-serif;
  color: #202939;
  /* -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; Standard syntax */
}

.inter-300 {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.tooltip-content{
  z-index: 1000;
  background-color: white ;
}
.p-checkbox .p-checkbox-box{
  border-width: 1px !important;
}

[type='text']:focus{
  --tw-ring-color: none !important;
}

.focus\:ring-4:focus {
  box-shadow: none !important;
}
.focus\:ring-cyan-600:focus {
  --tw-ring-opacity: 0;
  --tw-ring-color: none;
}
* {
  --tw-ring-color: rgb(0 0 0 / 0) !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.p-datatable .p-sortable-column.p-highlight {
  color: #0072ff !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #0072ff !important;
}

.basic-single .select__control{
  background-color: #f9fafb;
}

.use-spring-carousel-main-wrapper{
  overflow: hidden;
}

.use-spring-carousel-item{
  padding:  0 10px 20px 10px;
}

.text-gray-900{
  color: #202939 !important;
}


.ai-btn {
  height: 43px;
  padding: 0 20px;
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background: linear-gradient(133deg,#080f18,#2c248d 99%);
  cursor: pointer;
  transition: 200ms
}
.ai-btn::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 4px;
  background: linear-gradient(133deg,#080f18,#2c248d 99%);
  z-index: -1;
  transition: 200ms;
  color: white
}
.ai-btn::after {
  content: attr(data);
  font-size: 16px;
  
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms
}

.z-1{
  z-index: 1;
}
.ai-btn:hover::after{
  /* color: white; */
}