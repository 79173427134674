.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  top: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbe0e8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.active {
  background-color: #0072ff;
  box-shadow: 0 0 1px #0072ff;
}

.active.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(18px);
}

table {
  border-collapse: collapse;
  border-radius: 1rem;
  border-style: hidden; /* hide standard table (collapsed) border */
  border-collapse: separate;
  border-spacing: 0px; /* Adjust the second value to set the desired vertical gap  */
}

table td {
  color: #5a5c73;
  border-bottom: 1px solid #e2e8f0;
  padding: 5px;
}

table  th {
  font-weight: 600;
}
 th {
  background-color: #f8f9fb;
  border-right: 1px solid #e2e8f0;
  border-top: 1px solid #e2e8f0;
}

td {
  border-right: 1px solid #e2e8f0;
}

 td:first-child {
  text-align: center;
}



.basic-single .select__multi-value{
  background-color: #EDF5FF;
}

.basic-single  .select__control{
  border: 1px solid #cad1db;
  border-radius: 0.5rem;
}

.basic-single .select__value-container {
  padding: 3px 10px;
}

.basic-single  .select__multi-value__label{
  padding: 5px 10px;
  font-weight: 600;
}

.fb-button-main-element{
  padding-inline: 6px;
  border-radius: 4px; 
  font-size: 14px; height: 36px; 
  background-color: rgb(26, 119, 242); color: rgb(255, 255, 255); 
  border: 0px; font-weight: bold; display: flex;
}
.f_logo_f{
 fill: rgb(26, 119, 242); 
}
.fb_button_label {
  margin: auto;
  pointer-events: none;
}

.fb_button_label_element {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

.single_button_svg_logo {
  margin-bottom: .08em;
}


.fb_button_svg_logo {
  height: 1.33em;
  margin-left: .4em;
  margin-right: .4em;
  padding: .065em;
}

svg:not(:root) {
  overflow-clip-margin: content-box;
  overflow: hidden;
}

.fb_button_label {
  margin: auto;
  pointer-events: none;
}

.fb_button_label_element {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color:  #d8dce0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d8dce0;
}
