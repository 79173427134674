.transition-container {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  
  .transition-container.forward div {
    animation: slide-in-right 0.5s ease forwards;
  }
  
  .transition-container.backward div {
    animation: slide-in-left 0.5s ease forwards;
  }
  

  @keyframes slide-in-right {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  

  @keyframes slide-in-left {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  