@tailwind base;
@tailwind components;
@tailwind utilities;

/* .p-tabview .p-tabview-nav {
    @apply border-b border-gray-200;
  }
  
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    @apply border-0 text-gray-600 hover:text-gray-900;
  }
  
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    @apply border-b-2 border-blue text-blue;
  } */
  
  .p-tabview .p-tabview-panels,
.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: transparent !important;
}